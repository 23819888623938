import React, {useContext} from 'react';
import {Box, Button, Card, CardActions, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import ImageStepper from "./ImageStepper";
import {Favorite} from "@mui/icons-material";
import theme from "../theme";
import {addToShoppingCart} from "../service/ShoppingCart";
import {AuthContext} from "../authContext";

export const ProductItem = ({product, shop}) => {
    const {user, requestAuth, updateShoppingCart} = useContext(AuthContext);

    function addToCart() {
        if (user) {
            addToShoppingCart(user, shop.uri, product.id).then(r => updateShoppingCart());
        } else {
            requestAuth();
        }
    }

    return (
        <Card sx={{width: '100%'}} variant={"outlined"}>
            <CardHeader title={<>
                <span>{product.name}</span>
                <CardActions sx={{float: "right"}}>
                    <IconButton title={"Add to wishlist"}><Favorite/></IconButton>
                </CardActions>
            </>} subheader={product.title}/>
            <CardContent>
                <ImageStepper images={product.images}/>
            </CardContent>
            <CardContent>
                <Box>
                    <Typography variant={"body1"}>{product.description}</Typography>
                </Box>
            </CardContent>
            <CardContent>
                <Box sx={{textAlign: "center"}}>
                    <Typography variant={"h4"} color={theme.palette.primary.main}>${product.price.value}</Typography>
                </Box>
            </CardContent>
            <CardActions>
                <Button size={"large"} variant={"outlined"} onClick={addToCart} fullWidth>Add to cart</Button>
            </CardActions>
        </Card>
    );
}