export const payOrder = (orderId, card, payer) => {
    return fetch(`https://rappit.io/payment/v2/spf/order/${orderId}/process`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            card: card,
            payer: payer
        })
    });
}

export const createPayment = (orderId, payer) => {
    return fetch(`https://rappit.io/payment/v2/spf/order/${orderId}/payment`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(payer)
    });
}

export const processPayment = (paymentId, card) => {
    return fetch(`https://rappit.io/payment/v2/spf/payment/${paymentId}/process`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(card)
    });
}
