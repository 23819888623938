import {deepOrange, lightBlue, red} from "@mui/material/colors";

const rappitTheme = {
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        // fontWeightRegular: 200,
        fontWeightBold: 300,
        // allVariants: {
        //     fontWeight: 200
        // },
    },
    shape: {
        borderRadius: 5
    },
    palette: {
        primary: {
            main: deepOrange["500"],
        },
        secondary: {
            main: lightBlue["600"]
        },
        danger: {
            main: red["600"]
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    padding: 'none',
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: deepOrange["500"]
                }
            }
        }
    }
};

export default rappitTheme;
