import React, {useContext, useEffect, useState} from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    Card,
    CardActionArea,
    CardHeader,
    Container,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import {ProductItem} from "../component/ProductItem";
import {firestore} from "../firebase";
import {collection, doc, getDoc, getDocs, query} from "@firebase/firestore";
import {useNavigate, useParams} from "react-router";
import {AddCircleOutline, ShoppingCart} from "@mui/icons-material";
import {AuthContext} from "../authContext";
import {Link} from "react-router-dom";
import Badge from "@mui/material/Badge";

export const ShopPage = () => {
    const {shopId} = useParams('shopId');
    const {user, changeShop, shoppingCart} = useContext(AuthContext);
    const [shop, setShop] = useState(false);
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const shopRef = doc(firestore, '/shops/' + shopId);
        const productsRef = collection(shopRef, 'products');
        getDoc(shopRef).then(value => {
            setShop({uri: value.id, ...value.data()});
        });
        getDocs(query(productsRef)).then(
            value => setProducts(value.docs.map(function (v) {
                return {id: v.id, ...v.data()}
            }))
        );
        changeShop(shopId);
    }, [shopId]);

    const renderProducts = () => {
        if (shop) {
            return (
                <>
                    <Grid container spacing={2} sx={{marginBottom: '80px'}}>
                        {user && shop.customer === user.uid &&
                        <Grid item xs={12} md={4}>
                            {renderAddNewProduct()}
                        </Grid>
                        }
                        {products.map(product => (
                                <Grid item xs={12} md={4} key={product.title}>
                                    <ProductItem product={product} shop={shop}/>
                                </Grid>
                            )
                        )}
                    </Grid>
                    {shoppingCart && shoppingCart.total &&
                    <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                        <BottomNavigation sx={{height: '80px'}} showLabels>
                            <BottomNavigationAction onClick={() => navigate(`/${shopId}/cart`)}
                                icon={<Badge badgeContent={shoppingCart?.cart?.length} color="success">
                                    <ShoppingCart fontSize={"large"}/>
                                </Badge>
                                } label={'Shopping cart'}>

                            </BottomNavigationAction>
                        </BottomNavigation>
                    </Paper>
                    }
                </>
            );
        }
        return (
            <Typography variant={"h5"}>No products available at the moment... <Link to={'../'}>Go
                back.</Link></Typography>
        );
    }

    const renderAddNewProduct = () => {
        return (
            <Card sx={{width: '100%', height: '100%'}} variant={"outlined"}>
                <CardActionArea sx={{minHeight: '100px', transition: "none", height: '100%', textAlign: "center"}}
                                style={{backgroundColor: "#ebebeb"}} component={Link} to={`${shop.uri}/new`}>
                    <CardHeader title='Add new product'/>
                    <AddCircleOutline sx={{fontSize: '5em'}} color={"primary"}/>
                </CardActionArea>
            </Card>
        );
    }

    if (shop) {
        return (
            <Container maxWidth={"lg"}>
                {renderProducts()}
            </Container>
        );
    } else {
        return (<span>loading</span>);
    }
}
