import * as React from 'react';
import {useContext} from 'react';
import {AuthContext} from "../authContext";
import {Card, CardContent, CardHeader, Container} from "@mui/material";
import {PersonalInfoWidget} from "../component/PersonalInfo";

export const ProfilePage = () => {
    const {user} = useContext(AuthContext);
    return (
        <Container fixed maxWidth={"sm"}>
            <Card>
                <CardHeader title='Personal information'/>
                <CardContent>
                    <PersonalInfoWidget/>
                </CardContent>
            </Card>
        </Container>
    );
}