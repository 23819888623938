import React from 'react';
import {firestore} from "../firebase";
import {collection, getDocs, query} from "@firebase/firestore";
import {Card, CardContent, CardHeader, Container, Grid} from "@mui/material";
import {AuthContext} from "../authContext";
import {Link} from "react-router-dom";

const shopsRef = collection(firestore, '/shops');
const q = query(shopsRef);

export class HomePage extends React.Component {
    state = {
        shops: [],
    }
    static contextType = AuthContext;

    componentDidMount() {
        let fetch = [];
        getDocs(q).then(value => {
            value.forEach(result => {
                fetch.push({key: result.ref.id, data: result.data()});
            });
            this.setState({shops: fetch});
        });
    }

    render() {
        if (this.state.shops.length > 0) {
            return (
                <React.Fragment>
                    <Container maxWidth={"xl"} >
                        <Grid container spacing={2}>
                            {this.state.shops.map(shop =>
                                <Grid item md={2} key={shop.data.name}>
                                    <Link to={shop.key}  style={{textDecoration: 'none'}}>
                                    <Card variant={"elevation"} elevation={0}>
                                                <img draggable={false} width={'100%'}
                                                     alt={shop.data.name}
                                                     src={'https://res.cloudinary.com/rappit/image/upload/c_scale,w_200,h_150/' + shop.data.logo}/>
                                        <CardHeader title={shop.data.name} subheader={shop.data.description}/>
                                    </Card>
                                    </Link>
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                </React.Fragment>
            );
        } else {
            return (<span>loading...</span>);
        }
    }
}
