import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../authContext";
import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    Container,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {ShopContext} from "../ShopContext";
import {Error, Verified} from "@mui/icons-material";
import * as Yup from "yup";
import {useFormik} from "formik";

const ShopSchema = Yup.object().shape({
    businessName: Yup.string()
        .min(5, 'Should be 5 to 25 characters.')
        .max(25, 'Should be 5 to 25 characters.')
        .required('Required'),
    category: Yup.string()
        .required('Required'),
    description: Yup.string()
        .min(10, 'Should be 10 to 300 characters.')
        .max(300, 'Should be 10 to 300 characters.')
        .required('Required'),
    logo: Yup.string()
        .required('Required'),
});

export const AccountPage = () => {
    const {user} = useContext(AuthContext);
    const {shops} = useContext(ShopContext);
    const [currentShop, setCurrentShop] = useState(null);

    useEffect(() => {
        if(currentShop) {
            validation.setFieldValue('businessName', currentShop.name);
            validation.setFieldValue('description', currentShop.description);
        }
    }, [currentShop]);

    const validation = useFormik({
        initialValues: {
            businessName: '',
            category: '',
            description: '',
            logo: null,
        },
        validationSchema: ShopSchema,
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: values => {
            updateShop(values);
        }
    });

    function updateShop(values) {

    }

    if(shops){
        setCurrentShop(shops.getCurrentShop());
    }
    if (currentShop) {
        return (
            <Container maxWidth={"sm"}>
                <Card>
                    <CardContent>
                        {shops &&
                        <Select fullWidth defaultValue={shops.shop.id}
                                onChange={e => shops.setCurrentShop(e.target.value)}>
                            {shops && shops.shops.map(s =>
                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                            )}
                        </Select>
                        }
                    </CardContent>
                    <CardHeader title='Shop information'/>
                    <CardContent>
                        <form>
                        <TextField id='businessName' name='businessName' value={validation.values.businessName}
                                   label={'Business name'}
                                   helperText={'Your business name that will be displayed in customer\'s invoice'}
                                   variant={"standard"} margin={"normal"} fullWidth required
                                   onBlur={validation.handleBlur}
                                   onChange={validation.handleChange}/>
                        {validation.touched.businessName && validation.errors.businessName &&
                        <Alert severity={"error"}>{validation.errors.businessName}</Alert>}
                        {/*<FormControl fullWidth margin={"normal"} required>*/}
                        {/*    <OutlinedInput*/}
                        {/*        id={'uri'}*/}
                        {/*        name={'uri'}*/}
                        {/*        spellCheck={false}*/}
                        {/*        size={"large"}*/}
                        {/*        onBlur={validation.handleBlur}*/}
                        {/*        onChange={(event) => verifyShopUrl(event.target.value)}*/}
                        {/*        startAdornment={<span className={classes.urlPart}>https://rappit.market/</span>}*/}
                        {/*        endAdornment={verifiedUrl === null ? "" : verifiedUrl ?*/}
                        {/*            <Verified fontSize={"large"} className={classes.verified}/> :*/}
                        {/*            <Error fontSize={"large"} className={classes.notVerified}/>}*/}
                        {/*        aria-describedby="Shop URL"*/}
                        {/*        inputProps={{*/}
                        {/*            'aria-label': 'Shop URL'*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*    <FormHelperText variant={"standard"}>Your shop page URL</FormHelperText>*/}
                        {/*    {validation.touched.uri && validation.errors.uri &&*/}
                        {/*    <Alert severity={"error"}>{validation.errors.uri}</Alert>}*/}
                        {/*</FormControl>*/}
                        <TextField id='description' name='description'
                                   label={'Business description'} helperText={'Describe your products in few sentences'}
                                   variant={"standard"} margin={"normal"} fullWidth required
                                   onBlur={validation.handleBlur}
                                   onChange={validation.handleChange}/>
                        {validation.touched.description && validation.errors.description &&
                        <Alert severity={"error"}>{validation.errors.description}</Alert>}
                        <FormControl fullWidth variant={"standard"} margin={"normal"} required>
                            <InputLabel>Business category</InputLabel>
                            <Select inputProps={{onChange: validation.handleChange}} id='category' name='category'
                                    value={validation.values.category}>
                                <MenuItem value={'Alimentos y bebidas'}>Alimentos y bebidas</MenuItem>
                                <MenuItem value={'Arte y ocio'}>Arte y ocio</MenuItem>
                                <MenuItem value={'Bebes y niños'}>Bebes y niños</MenuItem>
                                <MenuItem value={'Casa y jardín'}>Casa y jardín</MenuItem>
                                <MenuItem value={'Cámaras y ópticas'}>Cámaras y ópticas</MenuItem>
                                <MenuItem value={'Economía e industria'}>Economía e industria</MenuItem>
                                <MenuItem value={'Electrónica'}>Electrónica</MenuItem>
                                <MenuItem value={'Elementos religiosos y ceremoniales'}>Elementos religiosos y
                                    ceremoniales</MenuItem>
                                <MenuItem value={'Equipamiento deportivo'}>Equipamiento deportivo</MenuItem>
                                <MenuItem value={'Juegos y jueguetes'}>Juegos y jueguetes</MenuItem>
                                <MenuItem value={'Maletas y bolsos de viaje'}>Maletas y bolsos de viaje</MenuItem>
                                <MenuItem value={'Material de oficina'}>Material de oficina</MenuItem>
                                <MenuItem value={'Mobiliario'}>Mobiliario</MenuItem>
                                <MenuItem value={'Multimedia'}>Multimedia</MenuItem>
                                <MenuItem value={'Adultos'}>Adultos</MenuItem>
                                <MenuItem value={'Mascotas y animales'}>Mascotas y animales</MenuItem>
                                <MenuItem value={'Ropa y accesorios'}>Ropa y accesorios</MenuItem>
                                <MenuItem value={'Salud y belleza'}>Salud y belleza</MenuItem>
                                <MenuItem value={'Software'}>Software</MenuItem>
                                <MenuItem value={'Vehículos'}>Vehículos</MenuItem>
                            </Select>
                            <FormHelperText variant={"standard"}>Choose category of your products</FormHelperText>
                        </FormControl>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        );
    }
    return <span>Loading</span>;
}