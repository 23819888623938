import * as React from 'react';
import {Container} from "@mui/material";
import {LoginWidget} from "../component/LoginWidget";
import {useContext} from "react";
import {AuthContext} from "../authContext";
import {useNavigate} from "react-router";

export const SignInPage = ({signup}) => {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();

    if(user) {
        navigate('/');
    }
    return (
        <Container maxWidth={"sm"}>
            <LoginWidget signup={signup}/>
        </Container>
    );
}