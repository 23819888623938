import React, {useContext, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {AuthContext} from "../authContext";
import {LoginWidget} from "../component/LoginWidget";
import {Error, Verified} from "@mui/icons-material";
import {doc, getDoc} from "@firebase/firestore";
import {firestore} from "../firebase";
import {registerShop} from "../service/ShopService";
import {useFormik} from "formik";
import * as Yup from "yup";
import ImageUploader from "../component/ImageUploader";

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        color: theme.palette.primary.light
    },
    verified: {
        color: theme.palette.success.light
    },
    notVerified: {
        color: theme.palette.error.light
    },
    urlPart: {
        fontSize: "large",
        fontWeight: 300
    }
}));

const NewShopSchema = Yup.object().shape({
    businessName: Yup.string()
        .min(5, 'Should be 5 to 25 characters.')
        .max(25, 'Should be 5 to 25 characters.')
        .required('Required'),
    category: Yup.string()
        .required('Required'),
    description: Yup.string()
        .min(10, 'Should be 10 to 300 characters.')
        .max(300, 'Should be 10 to 300 characters.')
        .required('Required'),
    logo: Yup.string()
        .required('Required'),
    firstName: Yup.string()
        .min(1, 'Up to 25 characters.')
        .max(25, 'Up to 25 characters.')
        .required(),
    lastName: Yup.string()
        .min(1, 'Up to 25 characters.')
        .max(25, 'Up to 25 characters.')
        .required('Required.'),
    email: Yup.string().email().required('Required.'),
    phone: Yup.string()
        .matches(/^[0-9\-\+\.\(\)]+$/, 'Valid phone number like +507(0)833-1234 or 123-4567')
        .required('Required.'),
    address: Yup.string()
        .min(5, 'Should be 5 to 25 characters.')
        .max(25)
        .required('Required.'),
    additional: Yup.string()
        .max(50, 'Maximum 50 characters.'),
    city: Yup.string()
        .max(25)
        .required(),
    state: Yup.string()
        .max(25),
    postal: Yup.string()
        .max(15),
    uri: Yup.string()
        .min(5, 'Should be 5 to 15 characters.')
        .max(15, 'Should be 5 to 15 characters.')
        .required('Required')
});

export function RegisterShopPage({shop}) {
    const {user} = useContext(AuthContext);

    const validation = useFormik({
        initialValues: {
            businessName: '',
            category: '',
            description: '',
            logo: null,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            additional: '',
            city: '',
            state: '',
            postal: '',
            uri: '',
        },
        validationSchema: NewShopSchema,
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: values => {
            register(values);
        }
    });
    const classes = useStyles();

    let timeout = 0;

    const [verifiedUrl, setVerifiedUrl] = useState(null);

    useEffect(() => {
        if (user) {
            console.log('Got user ' + user.displayName);
            validation.setFieldValue('firstName', user.displayName?.split(' ')[0], true);
            validation.setFieldValue('lastName', user.displayName?.split(' ')[1], true);
            validation.setFieldValue('email', user.email, true);
            if (user.phoneNumber) {
                validation.setFieldValue('phone', user.phoneNumber, true);
            }
        }
    }, [user]);

    useEffect(() => {
        if (!verifiedUrl) {
            validation.setFieldError('uri', 'Shop with that URL already exist. Try another name.');
        }
    }, [verifiedUrl]);

    if (user == null || user.isAnonymous) {
        return (
            <Container maxWidth={"sm"}>
                <LoginWidget signup/>
            </Container>
        );
    }

    if (!user.emailVerified) {
        return (
            <span>Please verify your email!</span>
        );
    }

    function register(values) {
        registerShop(
            user,
            {
                dba: values.businessName,
                category: values.category,
                description: values.description,
                uri: values.uri,
                logo: values.logo,
                name: {
                    first: values.firstName,
                    'last': values.lastName
                },
                location: {
                    address: values.address,
                    additional: values.additional,
                    city: values.city,
                    state: values.state,
                    postal: values.postal,
                    country: 'PA'
                },
                email: values.email,
                phone: values.phone,
                customer: user.uid
            }
        ).then(res => window.location = 'https://rappit.market/' + validation.values.uri).catch(error => console.log(error))
    }

    function handleBusinessNameChange(event) {
        event.target.value.replace(/[^0-9a-zñáéíóúü]/);
        validation.handleChange(event);
        verifyShopUrl(event.target.value);
    }

    function verifyShopUrl(shopURL) {
        if (shopURL == null || shopURL === '') {
            validation.setFieldValue('uri', '', true);
            setVerifiedUrl(null);
            return;
        }
        let url = shopURL.toLowerCase().replace(' ', '')
            .replace(/[ñ]/, 'n')
            .replace(/[á]/, 'a')
            .replace(/[é]/, 'e')
            .replace(/[í]/, 'i')
            .replace(/[ó]/, 'o')
            .replace(/[úü]/, 'u')
            .replace(/[^a-z0-9-]/g, '');
        document.getElementById('uri').value = url;
        validation.setFieldValue('uri', url, true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function () {
                getDoc(
                    doc(firestore, '/shops/' + url)
                ).then(value => setVerifiedUrl(!value.exists()))
                    .catch(err => setVerifiedUrl(false));
            },
            700
        );
    }

    function imageUploaded(result) {
        validation.setFieldValue('logo', result.info.path, true);
    }

    return (
        <Container maxWidth={"sm"}>
            <Card>
                <CardHeader title={"Register a shop"} subheader={"Sign up your shop account to start selling now!"}
                            className={classes.header}/>
                <CardHeader title={"Contact information"} className={classes.header}/>
                <form id={'shop-registration-form'} onSubmit={validation.handleSubmit} onReset={validation.handleReset}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={6}>
                                <TextField id='firstName' name='firstName' value={validation.values.firstName}
                                           label={'First name'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.firstName && validation.errors.firstName &&
                                <Alert severity={"error"}>{validation.errors.firstName}</Alert>}
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField id='lastName' name='lastName' value={validation.values.lastName}
                                           label={'Last name'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.lastName && validation.errors.lastName &&
                                <Alert severity={"error"}>{validation.errors.lastName}</Alert>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField value={validation.values.email}
                                           label={'E-Mail'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           disabled/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id='phone' name='phone' value={validation.values.phone}
                                           label={'Phone'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.phone && validation.errors.phone &&
                                <Alert severity={"error"}>{validation.errors.phone}</Alert>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id='address' name='address'
                                           label={'Address'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.address && validation.errors.address &&
                                <Alert severity={"error"}>{validation.errors.address}</Alert>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField id='additional' name='additional'
                                           label={'Additional address'} helperText={'Building name/number'}
                                           variant={"standard"} margin={"normal"} fullWidth
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.additional && validation.errors.additional &&
                                <Alert severity={"error"}>{validation.errors.additional}</Alert>}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id='city' name='city'
                                           label={'City'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.city && validation.errors.city &&
                                <Alert severity={"error"}>{validation.errors.city}</Alert>}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id='state' name='state'
                                           label={'State'}
                                           variant={"standard"} margin={"normal"} fullWidth
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.state && validation.errors.state &&
                                <Alert severity={"error"}>{validation.errors.state}</Alert>}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id='postal' name='postal'
                                           label={'Postal code'}
                                           variant={"standard"} margin={"normal"} fullWidth
                                           onBlur={validation.handleBlur}
                                           onChange={validation.handleChange}/>
                                {validation.touched.postal && validation.errors.postal &&
                                <Alert severity={"error"}>{validation.errors.postal}</Alert>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled
                                           label={'Country'}
                                           variant={"standard"} margin={"normal"} fullWidth required
                                           defaultValue={'Panama'}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardHeader title={"Business information"} className={classes.header}/>
                    <CardContent>
                        <TextField id='businessName' name='businessName'
                                   label={'Business name'}
                                   helperText={'Your business name that will be displayed in customer\'s invoice'}
                                   variant={"standard"} margin={"normal"} fullWidth required
                                   onBlur={validation.handleBlur}
                                   onChange={handleBusinessNameChange}/>
                        {validation.touched.businessName && validation.errors.businessName &&
                        <Alert severity={"error"}>{validation.errors.businessName}</Alert>}
                        <FormControl fullWidth margin={"normal"} required>
                            <OutlinedInput
                                id={'uri'}
                                name={'uri'}
                                spellCheck={false}
                                size={"large"}
                                onBlur={validation.handleBlur}
                                onChange={(event) => verifyShopUrl(event.target.value)}
                                startAdornment={<span className={classes.urlPart}>https://rappit.market/</span>}
                                endAdornment={verifiedUrl === null ? "" : verifiedUrl ?
                                    <Verified fontSize={"large"} className={classes.verified}/> :
                                    <Error fontSize={"large"} className={classes.notVerified}/>}
                                aria-describedby="Shop URL"
                                inputProps={{
                                    'aria-label': 'Shop URL'
                                }}
                            />
                            <FormHelperText variant={"standard"}>Your shop page URL</FormHelperText>
                            {validation.touched.uri && validation.errors.uri &&
                            <Alert severity={"error"}>{validation.errors.uri}</Alert>}
                        </FormControl>
                        <TextField id='description' name='description'
                                   label={'Business description'} helperText={'Describe your products in few sentences'}
                                   variant={"standard"} margin={"normal"} fullWidth required
                                   onBlur={validation.handleBlur}
                                   onChange={validation.handleChange}/>
                        {validation.touched.description && validation.errors.description &&
                        <Alert severity={"error"}>{validation.errors.description}</Alert>}
                        <FormControl fullWidth variant={"standard"} margin={"normal"} required>
                            <InputLabel>Business category</InputLabel>
                            <Select inputProps={{onChange: validation.handleChange}} id='category' name='category'
                                    value={validation.values.category}>
                                <MenuItem value={'Alimentos y bebidas'}>Alimentos y bebidas</MenuItem>
                                <MenuItem value={'Arte y ocio'}>Arte y ocio</MenuItem>
                                <MenuItem value={'Bebes y niños'}>Bebes y niños</MenuItem>
                                <MenuItem value={'Casa y jardín'}>Casa y jardín</MenuItem>
                                <MenuItem value={'Cámaras y ópticas'}>Cámaras y ópticas</MenuItem>
                                <MenuItem value={'Economía e industria'}>Economía e industria</MenuItem>
                                <MenuItem value={'Electrónica'}>Electrónica</MenuItem>
                                <MenuItem value={'Elementos religiosos y ceremoniales'}>Elementos religiosos y
                                    ceremoniales</MenuItem>
                                <MenuItem value={'Equipamiento deportivo'}>Equipamiento deportivo</MenuItem>
                                <MenuItem value={'Juegos y jueguetes'}>Juegos y jueguetes</MenuItem>
                                <MenuItem value={'Maletas y bolsos de viaje'}>Maletas y bolsos de viaje</MenuItem>
                                <MenuItem value={'Material de oficina'}>Material de oficina</MenuItem>
                                <MenuItem value={'Mobiliario'}>Mobiliario</MenuItem>
                                <MenuItem value={'Multimedia'}>Multimedia</MenuItem>
                                <MenuItem value={'Adultos'}>Adultos</MenuItem>
                                <MenuItem value={'Mascotas y animales'}>Mascotas y animales</MenuItem>
                                <MenuItem value={'Ropa y accesorios'}>Ropa y accesorios</MenuItem>
                                <MenuItem value={'Salud y belleza'}>Salud y belleza</MenuItem>
                                <MenuItem value={'Software'}>Software</MenuItem>
                                <MenuItem value={'Vehículos'}>Vehículos</MenuItem>
                            </Select>
                            <FormHelperText variant={"standard"}>Choose category of your products</FormHelperText>
                        </FormControl>
                    </CardContent>
                </form>
                <CardContent>
                    <Box>
                        <InputLabel>Upload Shop logo image</InputLabel>
                        <FormControl fullWidth margin={"normal"} required>
                            <ImageUploader buttonText={'Upload logo'}/>
                        </FormControl>
                        <Box>
                            {validation.values.logo &&
                            <img
                                src={'https://res.cloudinary.com/rappit/image/upload/c_scale,w_400/' + validation.values.logo}/>}
                        </Box>
                    </Box>
                </CardContent>
                <CardActions>
                    <FormControl fullWidth margin={"normal"}>
                        <Button type={"submit"} form={'shop-registration-form'} variant={"contained"}>Register</Button>
                    </FormControl>
                </CardActions>
            </Card>
        </Container>
    );
}