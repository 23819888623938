import React, {useContext, useEffect, useState} from 'react';
import {
    BottomNavigation,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@mui/material";
import {Delete, Payment} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router";
import {collection, documentId, getDocs, query, where} from "@firebase/firestore";
import {firestore} from "../firebase";
import {AuthContext} from "../authContext";
import {checkoutCart, removeShoppingCartItem, setToShoppingCart} from "../service/ShoppingCart";

export const ShoppingCart = () => {
    const [products, setProducts] = useState(null);
    const {user, shoppingCart, changeShop, updateShoppingCart} = useContext(AuthContext);
    const {shopId} = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (shoppingCart && shoppingCart.cart && shoppingCart.cart.length > 0) {
            const productsRef = collection(firestore, 'shops', shopId, 'products');
            getDocs(
                query(
                    productsRef,
                    where(
                        documentId(), "in",
                        shoppingCart.cart.map((item) => item.product)
                    )
                )
            ).then(value => setProducts(new Map(value.docs.map(function (v) {
                return [v.id, v.data()]
            }))));
        } else {
            changeShop(shopId);
        }
    }, [shoppingCart]);

    function checkout() {
        checkoutCart(user, shopId).then(res => {
            if(res.ok) {
                res.json().then(invoice => navigate('/pay/' + invoice.id))
            }
        });
    }

    if (shoppingCart && shoppingCart.cart && shoppingCart.cart.length > 0 && products) {
        return (
            <>
                <Container maxWidth={"md"} sx={{marginBottom: '80px'}}>
                    {shoppingCart.cart.map((item, idx) =>
                        (
                            <Card key={'cart-item-' + idx} variant={"outlined"}>
                                <CardHeader title={<>
                                    <span>{products.get(item.product).name}</span>
                                    <CardActions sx={{float: "right"}}>
                                        <IconButton title={"Remove"} aria-label={"Remove"}
                                                    onClick={(e) => removeShoppingCartItem(user, shopId, item.product).then((a) => updateShoppingCart())}><Delete/></IconButton>
                                    </CardActions>
                                </>} subheader={products.get(item.product).title}/>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item flex xs={12} md={4}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    display: 'block',
                                                    minWidth: '200px',
                                                    // overflow: 'hidden',
                                                    width: '100%',
                                                    paddingRight: '10px'
                                                }}
                                                draggable={false}
                                                src={`https://res.cloudinary.com/rappit/image/upload/c_scale,w_200/${products.get(item.product).images[0]}`}
                                                alt='product image'
                                            />
                                        </Grid>
                                        <Grid item flex xs={12} md={8}>
                                            <Typography
                                                variant={"body1"}>{products.get(item.product).description}</Typography>
                                        </Grid>
                                        <Grid item flex xs={4}>
                                            <Box>
                                                <Typography variant={"subtitle1"}>Quantity</Typography>
                                                <Select value={item.quantity}
                                                        onChange={(e) => setToShoppingCart(user, shopId, item.product, e.target.value).then(e => updateShoppingCart())}>
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item flex xs={4}>
                                            <Box>
                                                <Typography variant={"subtitle1"}>Price</Typography>
                                                <Typography variant={"h6"}
                                                            color={"primary.light"}>${item.price.toFixed(2)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item flex xs={4}>
                                            <Box sx={{float: 'right'}}>
                                                <Typography variant={"subtitle1"}>Sub-total</Typography>
                                                <Typography variant={"h6"}
                                                            color={"primary.light"}>${(item.price * item.quantity).toFixed(2)}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )
                    )}
                </Container>
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <BottomNavigation sx={{height: '80px'}}>
                        <Box sx={{display: 'block', marginRight: '20px'}}>
                            <Typography variant={"h6"} color={"primary.light"}>Total: </Typography>
                            <Typography variant={"h4"}
                                        color={"primary.light"}>${shoppingCart.total.value.toFixed(2)}</Typography>
                        </Box>
                        <Button size={"large"} startIcon={<Payment/>} variant={"contained"} onClick={() => checkout()}>Check out</Button>
                    </BottomNavigation>
                </Paper>
            </>
        );
    }
    return (<span>Shopping cart is empty</span>);
}
