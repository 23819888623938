import React, {useContext, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Dialog,
    DialogActions,
    FormControl,
    FormHelperText,
    Hidden,
    IconButton,
    ImageList,
    ImageListItem,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import {AuthContext} from "../authContext";
import {Widget, WidgetLoader} from "react-cloudinary-upload-widget";
import {useParams} from "react-router";
import {Close, Error, Favorite, Verified} from "@mui/icons-material";
import {doc, getDoc} from "@firebase/firestore";
import {firestore} from "../firebase";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ImageStepper from "../component/ImageStepper";
import {registerProduct} from "../service/ShopService";

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        color: theme.palette.primary.light
    },
    verified: {
        color: theme.palette.success.light
    },
    notVerified: {
        color: theme.palette.error.light
    },
    urlPart: {
        fontSize: "large",
        fontWeight: 300,
    }
}));

const NewProductSchema = Yup.object().shape({
    name: Yup.string()
        .min(5, 'Should be 5 to 70 characters.')
        .max(70, 'Should be 5 to 70 characters.')
        .required('Required'),
    uri: Yup.string()
        .min(3)
        .max(20)
        .required(),
    title: Yup.string()
        .min(10, 'Should be 10 to 300 characters.')
        .max(300, 'Should be 10 to 300 characters.')
        .required('Required'),
    description: Yup.string()
        .max(300, 'At most 300 characters allowed.'),
    price: Yup.number()
        .min(1, 'Minimum 1 USD')
        .max(1000, 'Maximum 1000 USD')
        .required(),
    images: Yup.array()
        .min(1, 'At least 1 image is required.')
        .max(10, 'At most 10 images are allowed')
        .required()
});

export const AddProductPage = () => {
    const classes = useStyles();
    const theme = useTheme();
    let timeout = 0;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {shopId} = useParams();
    const {user} = useContext(AuthContext);
    const [verifiedUrl, setVerifiedUrl] = useState(null);
    const [preview, setPreview] = useState(false);

    const validation = useFormik({
        initialValues: {
            name: '',
            uri: '',
            title: '',
            description: '',
            type: 'OTHER',
            price: '',
            images: []
        },
        validationSchema: NewProductSchema,
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: values => {
            addProduct(values);
        }
    });

    useEffect(() => {
        if (!verifiedUrl) {
            validation.setFieldError('uri', 'Product with this URL already exist.');
        }
    }, [verifiedUrl]);

    function imageUploaded(result, validation) {
        validation.setFieldValue('images', [...validation.values.images, result.info.path], true);
    }

    function handleProductNameChange(event) {
        event.target.value.replace(/[^0-9a-zñáéíóúü]/);
        verifyProductUrl(event.target.value);
    }

    function verifyProductUrl(productURL) {
        if (productURL == null || productURL === '') {
            // document.getElementById('uri').value = '';
            validation.setFieldValue('uri', '', true);
            setVerifiedUrl(null);
            return;
        }
        let url = productURL.toLowerCase().replace(' ', '')
            .replace(/[ñ]/, 'n')
            .replace(/[á]/, 'a')
            .replace(/[é]/, 'e')
            .replace(/[í]/, 'i')
            .replace(/[ó]/, 'o')
            .replace(/[úü]/, 'u')
            .replace(/[^a-z0-9-]/g, '');
        document.getElementById('uri').value = url;
        validation.setFieldValue('uri', url, true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function () {
                getDoc(
                    doc(firestore, '/shops/' + shopId + '/products/' + url)
                ).then(value => setVerifiedUrl(!value.exists()))
                    .catch(err => setVerifiedUrl(false));
            },
            700
        );
    }

    function handlePreview(event) {
        if (validation.isValid) {
            setPreview(true);
        }
    }

    function addProduct(values) {
        registerProduct(user, shopId, values).then(res => window.location = 'https://rappit.market/' + shopId).catch(console.log);
    }

    return (
        <Container maxWidth={"md"}>
            <Card sx={{width: '100%'}}>
                <CardHeader title={'New product'} className={classes.header}/>
                <form id={'product-form'} onSubmit={validation.handleSubmit} onReset={validation.handleReset}>
                    <CardContent>
                        <TextField id='name' name='name'
                                   label={'Product name'} helperText={'Product short name'}
                                   variant={"standard"} margin={"normal"} fullWidth
                                   onBlur={validation.handleBlur}
                                   onChange={(event) => {
                                       handleProductNameChange(event);
                                       validation.handleChange(event)
                                   }}/>
                        {validation.touched.name && validation.errors.name &&
                        <Alert severity={"error"}>{validation.errors.name}</Alert>}
                        <FormControl fullWidth margin={"normal"}>
                            <Hidden mdUp><InputLabel
                                variant={"filled"}><span>https://rappit.market/{shopId}/</span></InputLabel></Hidden>
                            <OutlinedInput
                                id={'uri'} name={'uri'}
                                spellCheck={false}
                                size={"large"}
                                onBlur={validation.handleBlur}
                                onChange={(event) => {
                                    verifyProductUrl(event.target.value);
                                    validation.handleChange(event)
                                }}
                                startAdornment={<Hidden mdDown><span
                                    className={classes.urlPart}>https://rappit.market/{shopId}/</span></Hidden>}
                                endAdornment={verifiedUrl === null ? "" : verifiedUrl ?
                                    <Verified fontSize={"large"} className={classes.verified}/> :
                                    <Error fontSize={"large"} className={classes.notVerified}/>}
                                aria-describedby="Product URL"
                                inputProps={{
                                    'aria-label': 'Product URL'
                                }}
                            />
                            <FormHelperText variant={"standard"}>Your product page URL</FormHelperText>
                            {validation.touched.uri && validation.errors.uri &&
                            <Alert severity={"error"}>{validation.errors.uri}</Alert>}
                        </FormControl>
                        <TextField name='title' label={'Product title'}
                                   helperText='This is a short text that provides a quick overview of the product'
                                   variant={"standard"} margin={"normal"} fullWidth
                                   onBlur={validation.handleBlur}
                                   onChange={validation.handleChange}
                        />
                        {validation.touched.title && validation.errors.title &&
                        <Alert severity={"error"}>{validation.errors.title}</Alert>}
                        <TextField name='description' label={'Description'} helperText='Product details and specifics'
                                   variant={"standard"} margin={"normal"} fullWidth onBlur={validation.handleBlur}
                                   onChange={validation.handleChange}
                        />
                        {validation.touched.description && validation.errors.description &&
                        <Alert severity={"error"}>{validation.errors.description}</Alert>}
                        <FormControl fullWidth variant={"standard"} margin={"normal"}>
                            <InputLabel>Product type</InputLabel>
                            <Select inputProps={{onChange: validation.handleChange}} id='type' name='type'
                                    value={validation.values.type}>
                                <MenuItem value='CLOTHES'>CLOTHES</MenuItem>
                                <MenuItem value='FOOD'>FOOD</MenuItem>
                                <MenuItem value='ELECTRONICS'>ELECTRONICS</MenuItem>
                                <MenuItem value='SUBSCRIPTION'>SUBSCRIPTION</MenuItem>
                                <MenuItem value='OTHER'>OTHER</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField name='price' label='Price in USD' helperText='Price per piece'
                                   type={"number"} variant={"standard"} margin={"normal"} fullWidth
                                   onBlur={validation.handleBlur}
                                   onChange={validation.handleChange}
                        />
                        {validation.touched.price && validation.errors.price &&
                        <Alert severity={"error"}>{validation.errors.price}</Alert>}
                    </CardContent>
                </form>
                <form onSubmit={event => event.preventDefault()}>
                    <CardHeader title={'Images'} className={classes.header}/>
                    <CardContent>
                        <Box>
                            <InputLabel>Upload product images</InputLabel>
                            <FormControl fullWidth margin={"normal"} required>
                                {validation.values.images.length < 11 &&
                                <React.Fragment>
                                    <WidgetLoader/>
                                    <Widget resourceType={'image'} uploadPreset={'z5kpiznv'}
                                            sources={['local']}
                                            hideAdvancedOptions={true}
                                            folder={shopId + '/products'}
                                            cloudName={'rappit'}
                                            onSuccess={res => {
                                                imageUploaded(res, validation)
                                            }}
                                            buttonText={'Upload images'}
                                            style={{
                                                color: 'white',
                                                border: 'none',
                                                width: '100%',
                                                height: '40px',
                                                backgroundColor: theme.palette.primary.light,
                                                font: theme.typography.fontFamily,
                                                fontSize: 'large',
                                                borderRadius: '4px',
                                            }}/>
                                </React.Fragment>}
                            </FormControl>
                            <Box>
                                {validation.errors.images && validation.touched.images &&
                                <Alert severity={"error"}>{validation.errors.images}</Alert>}
                                {validation.values.images.length > 0 &&
                                <ImageList gap={5} cols={5} variant={"masonry"} sx={{width: '100%'}}>
                                    {validation.values.images.map(image =>
                                        <ImageListItem key={image}>
                                            <img
                                                src={'https://res.cloudinary.com/rappit/image/upload/c_pad,w_200/' + image}/>
                                        </ImageListItem>
                                    )}
                                </ImageList>
                                }
                            </Box>
                        </Box>
                    </CardContent>
                    <CardActions sx={{float: "right"}}>
                        <Button size={"large"} variant={"outlined"} color={"secondary"}
                                type={"button"} onClick={handlePreview}>Preview</Button>
                        <Button form='product-form' size={"large"} variant={"contained"} color={"secondary"}
                                type={"submit"}>Create</Button>
                    </CardActions>
                </form>
            </Card>
            <Dialog maxWidth={"xs"} fullScreen={fullScreen} open={preview} onClose={() => setPreview(false)}
                    scroll={"body"}>
                <DialogActions><IconButton onClick={() => setPreview(false)}><Close/></IconButton></DialogActions>
                <Card variant={"outlined"}>
                    <CardHeader title={validation.values.name} subheader={validation.values.title}/>
                    <CardActions sx={{float: "right"}}>
                        <IconButton title={"Add to wishlist"}><Favorite/></IconButton>
                    </CardActions>
                    <CardContent>
                        <ImageStepper images={validation.values.images}/>
                    </CardContent>
                    <CardContent>
                        <Box>
                            <Typography variant={"body1"}>{validation.values.description}</Typography>
                        </Box>
                    </CardContent>
                    <CardContent>
                        <Box sx={{textAlign: "center"}}>
                            <Typography variant={"h4"}
                                        color={theme.palette.primary.main}>${validation.values.price}</Typography>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button size={"large"} variant={"outlined"} fullWidth>Add to cart</Button>
                    </CardActions>
                </Card>
            </Dialog>
            <div id='uploadRootContainer'>
                <form id='uploadForm'/>
            </div>
        </Container>
    );
}