import * as React from 'react';
import {useContext, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {AuthContext} from "../authContext";
import {Link} from "react-router-dom";
import logo from '../logo-simple-white.svg';
import {ShoppingCart} from "@mui/icons-material";
import {Avatar, Button} from "@mui/material";
import {useNavigate} from "react-router";

export default function PrimarySearchAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {user, signOut, shoppingCart, shopId} = useContext(AuthContext);
    const navigate = useNavigate();

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/*Signed in*/}
            {user && <MenuItem component={Link} to={'/profile'} onClick={handleMenuClose}>Profile</MenuItem>}
            {user && <MenuItem component={Link} to={'/account'} onClick={handleMenuClose}>My account</MenuItem>}
            {user && <MenuItem component={Link} to={'/register'} onClick={handleMenuClose}>Become a shop</MenuItem>}
            {user && <MenuItem onClick={() => {
                handleMenuClose();
                signOut();
            }}>Sign out</MenuItem>}
            {/*Not signed in*/}
            {!user && <MenuItem component={Link} to={'/signin'} onClick={handleMenuClose}>Log in</MenuItem>}
            {!user && <MenuItem component={Link} to={'/signup'} onClick={handleMenuClose}>Sign up</MenuItem>}
            {!user && <MenuItem component={Link} to={'/register'} onClick={handleMenuClose}>Become a shop</MenuItem>}
        </Menu>
    );

    return (
        <Box sx={{flexGrow: 1, marginBottom: '10px'}}>
            <AppBar position="static">
                <Toolbar>
                    <Link to={'/'}><img src={logo} alt={'rappit'} height={35}/></Link>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{display: {xs: 'none', sm: 'block'}}}
                    >
                        marketplace
                    </Typography>
                    {/*<Search>*/}
                    {/*    <SearchIconWrapper>*/}
                    {/*        <SearchIcon />*/}
                    {/*    </SearchIconWrapper>*/}
                    {/*    <StyledInputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        inputProps={{ 'aria-label': 'search' }}*/}
                    {/*    />*/}
                    {/*</Search>*/}
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Button component={Link} to={'/register'} size="large" aria-label="create shop" color={"secondary"} variant={"contained"}>
                            Create shop
                        </Button>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <IconButton onClick={(event) => navigate(`/${shopId}/cart`)} size="large" edge={"end"} aria-label="shopping cart" color="inherit">
                            <Badge badgeContent={shoppingCart?.cart?.length} color="success">
                                <ShoppingCart fontSize={"large"}/>
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >

                            {user && user.photoURL ? <Avatar src={user.photoURL}/> :
                                <AccountCircle fontSize={"large"}/>}
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </Box>
    );
}
