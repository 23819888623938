export const addToShoppingCart = (user, shopId, productId) => {
    return user.getIdToken().then(token =>
        fetch(
            `https://rappit.io/marketplace/v1/shop/${shopId}/cart/${productId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
            }
        )
    );
}

export const setToShoppingCart = (user, shopId, productId, quantity) => {
    return user.getIdToken().then(token =>
        fetch(
            `https://rappit.io/marketplace/v1/shop/${shopId}/cart/${productId}?quantity=${quantity}`,
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
            }
        )
    );
}

export const getShoppingCart = (user, shopId) => {
    return user.getIdToken().then(token =>
        fetch(
            `https://rappit.io/marketplace/v1/shop/${shopId}/cart`,
            {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
            }
        )
    );
}

export const removeShoppingCartItem = (user, shopId, productId) => {
    return user.getIdToken().then(token =>
        fetch(
            `https://rappit.io/marketplace/v1/shop/${shopId}/cart/${productId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
            }
        )
    );
}

export const checkoutCart = (user, shopId) => {
    return user.getIdToken().then(token =>
        fetch(
            `https://rappit.io/marketplace/v1/shop/${shopId}/cart/order`,
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
            }
        )
    );
}

