export const registerShop = (user, shopForm) => {
    return user.getIdToken().then(token =>
        fetch(
            "https://rappit.io/marketplace/v1/shop",
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
                body: JSON.stringify(shopForm)
            }
        )
    );
}

export const registerProduct = (user, shopId, productForm) => {
    return user.getIdToken().then(token =>
        fetch(
            `https://rappit.io/marketplace/v1/shop/${shopId}/product`,
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'X-Authorization-Rappit': token
                },
                body: JSON.stringify(productForm)
            }
        )
    );
}

