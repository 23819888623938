import React from 'react';
import MaskedInput from "react-text-mask";
import {useTranslation} from "react-i18next";
import {Alert, Grid, TextField} from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";
import {IMaskInput} from "react-imask";

export function CardForm({showBilling, onCardSet}) {
    const {t} = useTranslation('card');

    const CreditCardSchema = Yup.object().shape({
        number: Yup.string()
            .test(
                'test-number',
                t('card.number_invalid'),
                cardNumberLuhnCheck
            ).required(),
        expire: Yup.string()
            .test(
                'test-expire',
                t('card.expire_invalid'),
                validateExpire
            ).required(),
        cvv: Yup.number()
            .min(1)
            .max(999)
            .required(),
        firstName: Yup.string()
            .min(1, 'Should be at least 1 characters.')
            .max(70, 'Should be at most 70 characters.')
            .required('Required'),
        lastName: Yup.string()
            .min(2, 'Should be at least 1 characters.')
            .max(70, 'Should be at most 70 characters.')
            .required('Required'),
        address: Yup.string()
            .min(2, 'Should be at least 1 characters.')
            .max(70, 'Should be at most 70 characters.')
            .required('Required'),
        city: Yup.string()
            .min(2, 'Should be at least 1 characters.')
            .max(70, 'Should be at most 70 characters.')
            .required('Required'),
        state: Yup.string()
            .min(2, 'Should be at least 1 characters.')
            .max(70, 'Should be at most 70 characters.')
            .required('Required'),
        email: Yup.string().email().required('Required.'),

    });

    const validation = useFormik({
        initialValues: {
            number: '',
            expire: '',
            cvv: '',
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            state: '',
            email: ''
        },
        validationSchema: CreditCardSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: values => {
            console.log(values);
        }
    });

    function validateExpire(val) {
        return true;
    }

    return (
        <React.Fragment>
            <form id='card-info' onSubmit={validation.handleSubmit} onReset={validation.handleReset}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label={t('card.number')} id='number' value={validation.values.number} fullWidth
                                   variant={"standard"} margin={"dense"} InputProps={{
                            inputComponent: CardMaskCustom,
                            inputProps: {
                                onChange: validation.handleChange,
                                onBlur: validation.handleBlur,
                                name: 'number'
                            }
                        }}/>
                        {validation.touched.number && validation.errors.number &&
                        <Alert severity={"error"}>{validation.errors.number}</Alert>}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label={t('card.expire')} id='expire' value={validation.values.expire} fullWidth
                                   variant={"standard"} margin={"dense"}
                                   InputProps={{
                                       inputComponent: ExpireMaskCustom,
                                       inputProps: {
                                           onChange: validation.handleChange,
                                           onBlur: validation.handleBlur,
                                           name: 'expire'
                                       }
                                   }}/>
                        {validation.touched.expire && validation.errors.expire &&
                        <Alert severity={"error"}>{validation.errors.expire}</Alert>}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label={t('card.cvv')} value={validation.values.cvv} fullWidth
                                   variant={"standard"} margin={"dense"}
                                   InputProps={{
                                       inputComponent: CVVMaskCustom,
                                       inputProps: {
                                           onChange: validation.handleChange,
                                           onBlur: validation.handleBlur,
                                           name: 'cvv'
                                       }
                                   }}/>
                        {validation.touched.cvv && validation.errors.cvv &&
                        <Alert severity={"error"}>{validation.errors.cvv}</Alert>}
                    </Grid>
                </Grid>
                {showBilling && <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField label={t('payer.firstName')} id='firstName' fullWidth
                                   onChange={validation.handleChange} onBlur={validation.handleBlur}
                                   variant={"standard"} margin={"dense"}/>
                        {validation.touched.firstName && validation.errors.firstName &&
                        <Alert severity={"error"}>{validation.errors.firstName}</Alert>}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label={t('payer.lastName')} id='lastName' fullWidth
                                   onChange={validation.handleChange} onBlur={validation.handleBlur}
                                   variant={"standard"} margin={"dense"}/>
                        {validation.touched.lastName && validation.errors.lastName &&
                        <Alert severity={"error"}>{validation.errors.firstName}</Alert>}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label={t('payer.address')} id='address' fullWidth
                                   onChange={validation.handleChange} onBlur={validation.handleBlur}
                                   variant={"standard"} margin={"dense"}/>
                        {validation.touched.address && validation.errors.address &&
                        <Alert severity={"error"}>{validation.errors.address}</Alert>}
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label={t('payer.city')} id='city' fullWidth
                                   onChange={validation.handleChange} onBlur={validation.handleBlur}
                                   variant={"standard"} margin={"dense"}/>
                        {validation.touched.city && validation.errors.city &&
                        <Alert severity={"error"}>{validation.errors.city}</Alert>}
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label={t('payer.state')} id='state' fullWidth
                                   onChange={validation.handleChange} onBlur={validation.handleBlur}
                                   variant={"standard"} margin={"dense"}/>
                        {validation.touched.state && validation.errors.state &&
                        <Alert severity={"error"}>{validation.errors.state}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={t('payer.email')} id='email' fullWidth
                                   onChange={validation.handleChange} onBlur={validation.handleBlur}
                                   variant={"standard"} margin={"dense"}/>
                        {validation.touched.email && validation.errors.email &&
                        <Alert severity={"error"}>{validation.errors.email}</Alert>}
                    </Grid>
                </Grid>
                }
            </form>
        </React.Fragment>

    );
}

const CardMaskCustom = React.forwardRef(function (props, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            mask="#000 0000 0000 0000"
            definitions={{
                '#': /[3-6]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});

const ExpireMaskCustom = React.forwardRef(function (props, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            placeholder={'MM / YYYY'}
            mask="00 / 0000"
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});

const CVVMaskCustom = React.forwardRef(function (props, ref) {
    const {...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={ref}
            mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
            placeholderChar={'\u2000'}
        />
    );
});

function cardNumberLuhnCheck(cardNumber) {
// accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(cardNumber)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, bEven = false;
    cardNumber = cardNumber.replace(/\D/g, "");

    for (var n = cardNumber.length - 1; n >= 0; n--) {
        var cDigit = cardNumber.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) === 0;
}
