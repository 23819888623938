import React, {useState} from 'react';
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Input,
    InputLabel
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {AccountCircle, DeviceHub, Facebook, Google} from "@mui/icons-material";
import {auth, googleProvider} from '../firebase';
import {
    createUserWithEmailAndPassword,
    sendEmailVerification, signInAnonymously,
    signInWithEmailAndPassword,
    signInWithPopup,
    updateProfile
} from "@firebase/auth";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         // minWidth: "400px",
//         maxWidth: '500px'
//     },
// }));


export function LoginWidget({signup, allowAnon, onSuccess}) {
    // const classes = useStyles();
    const [signupView, setSignupView] = useState(signup);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);

    const doLogin = () => {
        signInWithEmail(email, password);
    }

    const clearError = () => {
        setError({field: null, message: null});
    }

    const clearForm = () => {
        setEmail(null);
        setPassword(null);
        setRepeatPassword(null);
    }

    const signInWithEmail = (email, password) => {
        setProcessing(true);
        signInWithEmailAndPassword(auth, email, password)
            .then(res => onSuccess && onSuccess())
            .catch(handleAuthError)
            .finally(() => setProcessing(false));
    };

    const signInAnon = () => {
        setProcessing(true);
        signInAnonymously(auth).then(res => onSuccess && onSuccess())
            .finally(() => setProcessing(false));
    }

    const handleAuthError = (error) => {
        console.log(error);
        switch (error.code) {
            case 'auth/missing-email':
                setError({field: 'email', message: 'E-Mail is required'});
                break;
            case 'auth/invalid-email':
                setError({field: 'email', message: 'Incorrect e-mail'});
                break;
            case 'auth/internal-error':
                setError({field: 'password', message: 'Password is required'});
                break;
            case 'auth/wrong-password':
                setError({field: 'password', message: 'Incorrect e-mail or password'});
                break;
            case 'auth/weak-password':
                setError({field: 'password', message: 'Password is too simple'});
                break;
            case 'auth/email-already-in-use':
                setError({field: 'registration-form', message: 'Account with this email already exist'});
                break;
            default:
                setError({field: 'login-form', message: 'Error occured, please try again. ' + error.message});
        }
    }

    const registerWithEmailAndPassword = () => {
        if (name == null || name === '') {
            setError({field: 'name', message: 'Name is required'})
            return false;
        }
        if (password != null && password !== '' && password === repeatPassword) {
            createUserWithEmailAndPassword(auth, email, password)
                .then(creds => {
                    updateProfile(creds.user, {displayName: name});
                    sendEmailVerification(creds.user);
                    onSuccess && onSuccess();
                })
                .catch(handleAuthError);
        } else {
            setError({field: 'registration-form', message: 'Check if password is correctly written'});
        }
    };

    const signInWithGoogle = async () => {
        signInWithPopup(auth, googleProvider)
            .then(res => onSuccess && onSuccess())
            .catch(err => setError(err.message))
    };

    return (
        <React.Fragment>
            <Card variant={"outlined"} hidden={signupView}>
                <CardHeader title={"Login"}/>
                <CardContent>
                    <Container fixed>
                        <FormControl margin={"normal"} fullWidth required variant={"standard"}>
                            <InputLabel>E-Mail</InputLabel>
                            <Input type={'email'} onChange={(event) => {
                                clearError();
                                setEmail(event.target.value)
                            }}/>
                            <FormHelperText>Your account E-Mail</FormHelperText>
                            {error.field === 'email' &&
                            <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth required variant={"standard"}>
                            <InputLabel>Password</InputLabel>
                            <Input type={'password'} onChange={(event) => {
                                clearError();
                                setPassword(event.target.value)
                            }}/>
                            <FormHelperText>Your password</FormHelperText>
                            {error.field === 'password' &&
                            <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                        </FormControl>
                        {error.field === 'login-form' &&
                        <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                    </Container>
                    <CardActions>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <Button disabled={processing} startIcon={processing ? <CircularProgress size={25}/> : null}
                                    size={"large"} variant={"contained"} onClick={() => doLogin()}>Login</Button>
                        </FormControl>
                    </CardActions>
                </CardContent>
                <CardContent>
                    <Container fixed>
                        <InputLabel>Or access with:</InputLabel>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <Button onClick={() => {
                                signInWithGoogle()
                            }} startIcon={<Google/>} variant={"outlined"}>Google</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <Button startIcon={<Facebook/>} variant={"outlined"}>Facebook</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <Button onClick={() => {
                                clearForm();
                                setSignupView(true);
                            }} startIcon={<AccountCircle/>} variant={"outlined"}>Create account</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <Button onClick={() => signInAnon()} startIcon={<DeviceHub/>} variant={"outlined"}>Continue without registration</Button>
                        </FormControl>
                    </Container>
                </CardContent>
            </Card>

            <Card variant={"outlined"} hidden={!signupView}>
                <CardHeader title={"Registration"}/>
                <CardContent>
                    <Container fixed>
                        <FormControl required margin={"normal"} fullWidth variant={"standard"}>
                            <InputLabel>Name</InputLabel>
                            <Input defaultValue={''} autoComplete={'none'} aria-autocomplete={'none'} type={'text'}
                                   onChange={(event) => setName(event.target.value)}/>
                            <FormHelperText>Your full name</FormHelperText>
                            {error.field === 'name' &&
                            <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                        </FormControl>
                        <FormControl required margin={"normal"} fullWidth variant={"standard"}>
                            <InputLabel>E-Mail</InputLabel>
                            <Input type={'email'} defaultValue={''} autoComplete={'none'} aria-autocomplete={'none'}
                                   onChange={(event) => setEmail(event.target.value)}/>
                            <FormHelperText>Your E-Mail</FormHelperText>
                            {error.field === 'email' &&
                            <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <InputLabel>New Password</InputLabel>
                            <Input type={'password'} defaultValue={''} autoComplete={'none'} aria-autocomplete={'none'}
                                   onChange={(event) => setPassword(event.target.value)}/>
                            <FormHelperText>Your new password</FormHelperText>
                            {error.field === 'password' &&
                            <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <InputLabel>Repeat Password</InputLabel>
                            <Input type={'password'} onBlur={(event) => setRepeatPassword(event.target.value)}/>
                            <FormHelperText>Repeat new password</FormHelperText>
                        </FormControl>
                        {error.field === 'registration-form' &&
                        <Alert variant={"standard"} severity={"error"}>{error.message}</Alert>}
                    </Container>
                    <CardActions>
                        <FormControl margin={"normal"} fullWidth variant={"standard"}>
                            <Button disabled={processing} startIcon={processing ? <CircularProgress size={25}/> : null}
                                    onClick={() => registerWithEmailAndPassword()} size={"large"}
                                    variant={"contained"}>Sign-up now</Button>
                        </FormControl>
                    </CardActions>
                </CardContent>
                <CardContent>
                    <Container fixed>
                        <InputLabel>Or access with:</InputLabel>
                        <FormControl margin={"normal"} fullWidth>
                            <Button onClick={() => {
                                signInWithGoogle()
                            }} startIcon={<Google/>} variant={"outlined"}>Google</Button>
                        </FormControl>
                        <FormControl margin={"normal"} fullWidth>
                            <Button startIcon={<Facebook/>} variant={"outlined"}>Facebook</Button>
                        </FormControl>
                    </Container>
                </CardContent>
                <CardContent>
                    <Container fixed>
                        <InputLabel>Already have an account?</InputLabel>
                        <FormControl margin={"normal"} fullWidth>
                            <Button onClick={() => {
                                clearForm();
                                setSignupView(false);
                            }} startIcon={<AccountCircle/>} variant={"outlined"}>Login</Button>
                        </FormControl>
                    </Container>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}