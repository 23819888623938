import {useRef, useState} from "react";
import {Box, Button, CircularProgress, LinearProgress} from "@mui/material";
import {green} from "@mui/material/colors";

export default function ImageUploader({buttonText}) {
    const fileSelect = useRef(null);
    const [image, setImage] = useState(null);
    const [progress, setProgress] = useState(0);

    async function handleImageUpload() {
        if (fileSelect) {
            fileSelect.current.click();
        }
    }

    function handleFiles(files) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            uploadFile(files[i]);
        }
    }

    function uploadFile(file) {
        const url = `https://api.cloudinary.com/v1_1/rappit/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        // Update progress (can be used to show progress indicator)
        xhr.upload.addEventListener("progress", (e) => {
            setProgress(Math.round((e.loaded * 100.0) / e.total));
            console.log(Math.round((e.loaded * 100.0) / e.total));
        });

        xhr.onreadystatechange = (e) => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                const response = JSON.parse(xhr.responseText);

                setImage(response.secure_url);
                console.log(response.secure_url);
            }
        };

        fd.append(
            "upload_preset",
            'z5kpiznv'
        );
        fd.append("tags", "browser_upload");
        fd.append("file", file);
        xhr.send(fd);
    }

    return (
        <>
            {image && (
                <div style={{position: 'flex', margin: '0 auto'}}>
                <img
                    src={image.replace("upload/", "upload/w_400/")}
                    style={{width: 400}}
                />
                </div>
            )}
                    <form>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                fullWidth variant={"outlined"}
                                onClick={handleImageUpload}
                                type="button"
                            >
                                {buttonText}
                            </Button>
                            {progress > 0 &&  progress < 100 && (
                                <CircularProgress
                                    value={progress}
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                        <input
                            ref={fileSelect}
                            type="file"
                            accept="image/*"
                            style={{display: "none"}}
                            onChange={(e) => handleFiles(e.target.files)}
                        />
                    </form>
        </>
    );
}