export const fetchOrder = (orderId) => {
    return fetch(`https://rappit.io/payment/v1/spf/order/${orderId}`, {
        method: 'GET'
    });
}

export const fetchInvoice = (user, invoiceId) => {
    return user.getIdToken().then(token =>
        fetch(`https://rappit.io/marketplace/v1/invoice/${invoiceId}`, {
            method: 'GET',
            headers: {
                'X-Authorization-Rappit': token
            }
        })
    );
}
