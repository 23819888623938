import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {ProductItem} from "../component/ProductItem";
import {firestore} from "../firebase";
import {doc, getDoc} from "@firebase/firestore";
import {useParams} from "react-router";

export function ProductPage() {
    const {shopId, productId} = useParams();
    const [shop, setShop] = useState(null);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const shopRef = doc(firestore, '/shops/' + shopId);
        const productRef = doc(shopRef, 'products/' + productId);
        getDoc(shopRef).then(value => {
            setShop({uri: value.id, ...value.data()});
        });
        getDoc(productRef).then(value => {
            setProduct({id: productId, ...value.data()});
        });
    }, [shopId, productId]);

    if (product && shop) {
        return (
            <Container maxWidth={"xl"}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <ProductItem product={product} shop={shop}/>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (<span>loading...</span>);
    }
}