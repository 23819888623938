import React, {useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import queryString from 'query-string';
import {makeStyles} from "@mui/styles";
import logo from "../logo-simple-white.svg";
import {useLocation, useParams} from "react-router";
import moment from "moment";
import ReactDOM from 'react-dom';
import {useTranslation} from "react-i18next";
import {fetchOrder} from "../service/OrderService";
import {createPayment, processPayment} from "../service/PaymentService";
import {CardForm} from "./CardForm";
import useScript from "react-cloudinary-upload-widget/src/_hooks_/useScript";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '430px',
        margin: "auto"
    },
    header: {
        // backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    loading: {
        textAlign: "center",
    },
}));

export function PaymentDetailsWidget({orderId}) {
    const classes = useStyles();
    const {t} = useTranslation('spf');
    const [paymentId, setPaymentId] = useState(null);
    const [order, setOrder] = useState(null);
    const [items, setItems] = useState(null);
    const [card, setCard] = useState(null);
    const [payer, setPayer] = useState(null);
    const [threeDS, setThreeDS] = useState(null);
    const qParams = queryString.parse(useLocation().search)
    let view = qParams.view;

    useScript('https://cdn.3dsintegrator.com/threeds.min.latest.js');

    useEffect(() => {
        if (orderId) {
            fetchOrder(orderId).then(res => res.ok ? res.json().then(setOrder) : console.log('Error fetching order ' + orderId))
        }
    }, [orderId]);

    function orderTable() {
        if (view === 'none') {
            return;
        }
        return (
            <React.Fragment>
                <Table size={"small"} padding={"none"}>
                    <TableBody>
                        <TableRow>
                            <TableCell variant={"head"}>{t('invoice.id')}</TableCell>
                            <TableCell>{order.reference}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={"head"}>{t('order.merchant')}</TableCell>
                            <TableCell><strong>{order.merchant.name}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={"head"}>{t('order.created')}</TableCell>
                            <TableCell>{moment(order.created).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                        </TableRow>
                        {order.type === 'RECURRING' &&
                        <React.Fragment>
                            <TableRow>
                                <TableCell variant={"head"}>{t('order.recurring.frequency')}</TableCell>
                                <TableCell>{order.recurring.frequency}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>{t('order.recurring.amount')}</TableCell>
                                <TableCell>{order.recurring.amount.currency} {ccyFormat(order.recurring.amount.value)}</TableCell>
                            </TableRow>
                        </React.Fragment>
                        }
                        {order.type === 'INSTALLMENT' &&
                        <React.Fragment>
                            <TableRow>
                                <TableCell variant={"head"}>{t('order.recurring.frequency')}</TableCell>
                                <TableCell>{order.recurring.frequency}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>{t('order.recurring.amount')}</TableCell>
                                <TableCell>{order.recurring.amount.currency} {ccyFormat(order.recurring.amount.value)}</TableCell>
                            </TableRow>
                            {order.recurring.count &&
                            <TableRow>
                                <TableCell variant={"head"}>{t('order.recurring.installments')}</TableCell>
                                <TableCell>{order.recurring.count}</TableCell>
                            </TableRow>
                            }
                        </React.Fragment>
                        }
                        <TableRow>
                            <TableCell variant={"head"}>{t('order.reference')}</TableCell>
                            <TableCell>{order.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={"head"}>{t('order.description')}&nbsp;</TableCell>
                            <TableCell>{order.description}</TableCell>
                        </TableRow>
                        {(view === 'short' || !(items && items.length > 0)) &&
                        <TableRow>
                            <TableCell variant={"head"}>{t('order.orderTotal')}</TableCell>
                            <TableCell><b>{order.amount.currency} {ccyFormat(order.amount.value)}</b></TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
                {view !== 'short' && order.items && order.items.length > 0 &&
                <TableContainer>
                    <Table size={"small"} padding={"none"}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={4}>{t('order.details')}</TableCell>
                                <TableCell align="right">{t('order.price')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('order.item.description')}</TableCell>
                                <TableCell align="left">{t('order.item.sku')}</TableCell>
                                <TableCell align="right">{t('order.item.quantity')}</TableCell>
                                <TableCell align="right">{t('order.item.price')}</TableCell>
                                <TableCell align="right">{t('order.item.sum')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.items.map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell align="left">{item.sku ? item.sku : '-'}</TableCell>
                                    <TableCell align="right">{item.quantity ? item.quantity : 1}</TableCell>
                                    <TableCell align="right">{ccyFormat(item.price.value)}</TableCell>
                                    <TableCell
                                        align="right">{ccyFormat(item.price.value * (item.quantity ? item.quantity : 1))}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell rowSpan={3}/>
                                <TableCell colSpan={3}>{t('order.item.subtotal')}</TableCell>
                                <TableCell align="right">{subTotal(order.items)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={3}>{t('order.item.tax')}</TableCell>
                                <TableCell align="right">{taxTotal(order.items)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}><strong>{t('order.item.total')}</strong></TableCell>
                                <TableCell align="right"><strong>USD</strong></TableCell>
                                <TableCell align="right"><strong>{order.amount.value.toFixed(2)}</strong></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </React.Fragment>
        )
    }

    const onCardSet = (cardIn, payerIn) => {
        setCard(cardIn);
        setPayer(payerIn);
    }

    const handlePay = (e) => {
        window.document.ThreeDS.verify();
        if (card) {
            createPayment(order.id, payer)
                .then(res => res.ok && res.json())
                .then(res => processPayment(res.id, card)
                    .then(res => res.ok && res.json())
                    .then(res => processingFrame(res.form))
                );
        }
    }

    const processingFrame = (form) => {
        if (!form) {
            return;
        }
        const paramsArray = [];
        Object.keys(form.inputs).forEach((key) => {
            paramsArray.push((<input type={'hidden'} key={key} name={key} value={form.inputs[key]}/>));
        });
        const formContent = (
            <div>
                <form id="cs-processing-form" className="form" action={form.action} method={'post'}>
                    {paramsArray}
                </form>
            </div>
        );
        ReactDOM.render(
            formContent,
            document.getElementById('processing-frame').contentDocument.body,
            () => document.getElementById('processing-frame').contentDocument.getElementById('cs-processing-form').submit());
    };

    if (!order) {
        return (
            <div className={classes.root + " " + classes.loading}>
                <CircularProgress size={75} color={"primary"}/>
                <Typography variant={"h5"} color={"primary"}>{t('order.loading_spinner')}</Typography>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Card className={classes.root}>
                <CardHeader title={orderTable(qParams.view, order, items)} className={classes.header}/>
                <CardContent>
                    <CardForm showBilling={!order.hasPayerSet} onCardSet={onCardSet}/>
                </CardContent>
                <Button size={"large"} startIcon={<img alt={""} height={"20px"} src={logo}/>} variant={"contained"}
                        color={"primary"} onClick={handlePay}
                        fullWidth={true}><strong>{t('order.pay_button')}&nbsp;${ccyFormat(order.amount.value)}</strong></Button>
            </Card>
            <iframe title={"processing-frame"} id="processing-frame" hidden={true}/>
        </React.Fragment>
    );
}

function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}

function taxTotal(items) {
    let taxTotal = 0.00;
    items.map(item => item.tax ? taxTotal += item.tax.value : 0);
    return taxTotal.toFixed(2);
}

function subTotal(items) {
    let subTotal = 0.00;
    items.map(item => item.price ? subTotal += item.price.value : 0);
    return subTotal.toFixed(2);
}

