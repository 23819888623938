import React, {useEffect, useState} from "react";
import {auth} from "./firebase";
import {Container, Modal} from "@mui/material";
import {LoginWidget} from "./component/LoginWidget";
import {getShoppingCart} from "./service/ShoppingCart";

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [shoppingCart, setShoppingCart] = useState(null);
    const [shopId, setShopId] = useState(null);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(setUser);
    }, [auth]);

    useEffect(() => {
        if (user && shopId) {
            updateShoppingCart();
        }
    }, [user, shopId]);

    function requestAuth() {
        setShowLogin(true);
    }

    function signOut() {
        auth.signOut().then(() => setShoppingCart(null));
    }

    function changeShop(shop) {
        setShopId(shop);
    }

    function updateShoppingCart() {
        if (user) {
            if (shopId) {
                getShoppingCart(user, shopId).then(res => res.ok ? res.json().then(setShoppingCart) : console.log('Error fetching shopping cart'));
            } else {
                setShoppingCart(null);
            }
        } else {
            requestAuth();
        }
    }

    return (
        <AuthContext.Provider value={{user, shoppingCart, requestAuth, signOut, changeShop, updateShoppingCart, shopId}}>
            {children}
            <Modal open={showLogin} keepMounted onClose={() => setShowLogin(false)}>
                <Container maxWidth={"sm"} sx={{
                    maxWidth: '400px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // p: 4,
                    bground: 'background.paper',
                    // border: '1px solid #000',
                    boxShadow: 24
                }}>
                    <LoginWidget onSuccess={() => setShowLogin(false)}/>
                </Container>
            </Modal>
        </AuthContext.Provider>
    );
};