import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import rappitTheme from "./theme"
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from "@mui/material";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {HomePage} from "./page/HomePage";
import {ShopPage} from "./page/ShopPage";
import {ProductPage} from "./page/ProductPage";
import {RegisterShopPage} from "./page/RegisterShopPage";
import {AuthProvider} from "./authContext";
import PrimaryAppBar from "./component/Header";
import {SignInPage} from "./page/SignInPage";
import {AccountPage} from "./page/AccountPage";
import {AddProductPage} from "./page/AddProductPage";
import {ProfilePage} from "./page/ProfilePage";
import {ShopProvider} from "./ShopContext";
import {PaymentPage} from "./page/PaymentPage";
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import card_en from "./translations/en/card.json";
import spf_en from "./translations/en/spf.json";
import spf_es from "./translations/es/spf.json";
import {ShoppingCartProvider} from "./ShoppingCartContext";
import {ShoppingCart} from "./page/ShoppingCart";

const theme = createTheme(rappitTheme);
i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: navigator.language || navigator.userLanguage,
    fallbackLng: 'en',
    resources: {
        en: {
            card: card_en,
            spf: spf_en
        },
        es: {
            spf: spf_es
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
                <AuthProvider>
                    <ShoppingCartProvider>
                        <Router>
                            <PrimaryAppBar/>
                            <Routes>
                                <Route path="/" element={<HomePage/>}/>
                                <Route exact path="/register" element={<RegisterShopPage/>}/>
                                <Route exact path="/signin" element={<SignInPage signup={false}/>}/>
                                <Route exact path="/signup" element={<SignInPage signup={true}/>}/>
                                <Route exact path="/account" element={<ShopProvider><AccountPage/></ShopProvider>}/>
                                <Route exact path="/profile" element={<ProfilePage/>}/>
                                <Route exact path="/pay/:invoiceId" element={<PaymentPage/>}/>
                                <Route exact path="/:shopId" element={<ShopPage/>}/>
                                <Route path="/:shopId/new" element={<AddProductPage/>}/>
                                <Route path="/:shopId/cart" element={<ShoppingCart/>}/>
                                <Route path="/:shopId/:productId" element={<ProductPage/>}/>
                            </Routes>
                        </Router>
                    </ShoppingCartProvider>
                </AuthProvider>
            </I18nextProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
