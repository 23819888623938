import * as React from 'react';
import {useContext, useEffect} from 'react';
import {AuthContext} from "../authContext";
import {Alert, Button, CardActions, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";

const PersonalInfoSchema = Yup.object().shape({
    name: Yup.string()
        .min(5, 'Should be at least 5 characters.')
        .max(70, 'Should be at most 70 characters.')
        .required('Required'),
    email: Yup.string().email().required('Required.'),
    phone: Yup.string()
        .matches(/^[0-9\-+.()]+$/, 'Valid phone number like +507(0)833-1234 or 123-4567')
        .required('Required.'),

});

export const PersonalInfoWidget = () => {
    const {user} = useContext(AuthContext);
    const validation = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: ''
        },
        validationSchema: PersonalInfoSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: values => {
            console.log(values);
        }
    });
    useEffect(() => {
        if (user) {
            validation.setValues({
                name: user.displayName,
                email: user.email,
                phone: user.phoneNumber || ''
            });
        }
    }, [user]);

    if (!user) {
        return (<span>loading...</span>);
    }

    return (
        <form id={'personal-info'} onSubmit={validation.handleSubmit} onReset={validation.handleReset}>
            <TextField id={'name'} name={'name'} value={validation.values.name}
                       onChange={validation.handleChange} onBlur={validation.handleBlur}
                       label={'Name'} helperText={'Your full name'}
                       variant={"standard"} margin={"normal"} fullWidth
            />
            {validation.touched.name && validation.errors.name &&
            <Alert severity={"error"}>{validation.errors.name}</Alert>}
            <TextField id={'email'} disabled name={'email'} value={validation.values.email}
                       onChange={validation.handleChange} onBlur={validation.handleBlur}
                       label={'E-Mail'} helperText={'E-Mail address'}
                       variant={"standard"} margin={"normal"} fullWidth
            />
            {validation.touched.email && validation.errors.email &&
            <Alert severity={"error"}>{validation.errors.email}</Alert>}
            <TextField id={'phone'} name={'phone'} value={validation.values.phone}
                       onChange={validation.handleChange} onBlur={validation.handleBlur}
                       label={'Phone'} helperText={'Contact phone number'}
                       variant={"standard"} margin={"normal"} fullWidth
            />
            {validation.touched.phone && validation.errors.phone &&
            <Alert severity={"error"}>{validation.errors.phone}</Alert>}
            <CardActions sx={{float: "right"}}>
                <Button size={"large"} type={"submit"} variant={"contained"}>Save</Button>
            </CardActions>
        </form>
    );
}