import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "./authContext";
import {collection, getDocs, query, where} from "@firebase/firestore";
import {firestore} from "./firebase";

export const ShopContext = React.createContext();

export class Shops {
    constructor(shops, shop) {
        this.shops = shops;
        this.shop = shop ? shop : shops[0];
    }

    getShops = () => {
        return this.shops;
    }

    getCurrentShop = () => {
        return this.shop;
    }

    setCurrentShop = (shop) => {
        this.shop = shop instanceof String ? this.shops.find((e) => e.id === shop) : shop;
        console.log('Current shop is set to: ' + shop);
    }
}

export const ShopProvider = ({children}) => {
    const [shops, setShops] = useState(null);
    const {user} = useContext(AuthContext);

    useEffect(() => {
        if (user) {
            getDocs(
                query(
                    collection(firestore, 'shops'),
                    where('customer', '==', user.uid)
                )
            ).then(shopDocs => shopDocs.docs.map(doc => {
                return {id: doc.id, ...doc.data()}
            })).then(docs => setShops(new Shops(docs)));
        }
    }, [user]);

    return (
        <ShopContext.Provider value={{shops}}>{children}</ShopContext.Provider>
    );
};