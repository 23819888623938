import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "./authContext";
import {getShoppingCart} from "./service/ShoppingCart";

export const ShoppingCartContext = React.createContext(null);

export const ShoppingCartProvider = ({children}) => {
    const [shoppingCart, setShoppingCart] = useState(null);
    const [shop, setShop] = useState(null);
    const {user} = useContext(AuthContext);

    useEffect(() => {
        updateShoppingCart();
        console.log('!!!!!!!!!!! Shopping cart updated');
    }, [user, shop]);

    function changeShop(shopId) {
        setShop(shopId);
    }

    function updateShoppingCart() {
        if (user && shop) {
            getShoppingCart(user, shop).then(res => res.ok ? res.json().then(setShoppingCart) : console.log('Error fetching shopping cart'));
        }
    }

    return (
        <ShoppingCartContext.Provider
            value={{shoppingCart, changeShop, updateShoppingCart, shop}}>{children}</ShoppingCartContext.Provider>
    );
};