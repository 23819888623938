import React, {useContext, useEffect, useState} from 'react';
import {Container} from "@mui/material";
import {useParams} from "react-router";
import {PaymentDetailsWidget} from "../component/PaymentDetailsWidget";
import {AuthContext} from "../authContext";
import {fetchInvoice} from "../service/OrderService";

export const PaymentPage = () => {
    const {invoiceId} = useParams();
    const {user} = useContext(AuthContext);
    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        if (invoiceId && user) {
            fetchInvoice(user, invoiceId).then(res => res.json()).then(setInvoice);
        }
    }, [invoiceId, user]);
    if (invoice) {
        return (
            <Container maxWidth={"sm"}>
                <PaymentDetailsWidget orderId={invoice.order}/>
            </Container>
        );
    }
    return (<span>Processing your order...</span>);
}